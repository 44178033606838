import CMSLayout from '@/layout/CMSLayout.vue'

export const invoiceRouter = {
  path: '/',
  component: CMSLayout,
  children: [
    {
      path: '/invoices',
      name: 'InvoicePage',
      // lazy load
      component: () => import('../page/InvoicePage.vue'),
      meta: {
        onlyWhenLoggedOut: true,
        public: true,
        title: 'Invoices'
      }
    }
  ]
}
